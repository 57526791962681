<template>
  <Details
    title="shared data key"
    resource="dataKeys"
    :base-path="basePath"
    data-provider="$raaDataProvider"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel entity-title="shared data key" new-entity></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <shared-data-key-form
        :initialValues="detailsProps.record"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import SharedDataKeyForm from "@/views/auth/raa-devtools/shared-data-keys/SharedDataKeyForm";

  export default {
    name: "SharedDataKeyCreate",
    components: {
      Details,
      DetailsSidePanel,
      SharedDataKeyForm,
    },
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.name.replace('create', 'index');
      },
    },
  }
</script>

<style scoped>

</style>
